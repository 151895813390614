
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0.3px;
}
:root {
  /* --main-color: #0205a1e8; */
  --main-color: #010320;
}

body {
   font-size: 15px;
   /* font-family: 'Poppins', sans-serif; */
   font-family: 'Lato', sans-serif;
   overflow: hidden auto;
   background-color: var(--main-color);
   color: #f5f5f5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: #f5f5f5;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}