.section-title {
    padding: 40px 0 20px 0;
}
.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
}
.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #c865fa;
    margin: 4px 10px;
}
.section-title p {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #FFF;
}

@media screen and (max-width: 1024px){
    .section-title h2 {
        font-size: 12px;
    }
    .section-title p {
        font-size: 25px;
    }
}