.btn {
    font-size: 16px;
    border: none;
    outline: none;
    padding: 13px 50px;
    background-color: #c865fa !important;
    color: #FFF;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
    margin: 0 auto;
    border-radius: 5px;
}