.input-group {
    margin-bottom: 20px;
}
.input-label {
   display: block;
   margin-bottom: 6px;
}
.input-container {
    position: relative;
}
.input-icon {
    position: absolute;
    font-size: 17px;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;
    border-right: 1px solid #757575;
    padding-right: 10px ;
    color: #d6d6d6;
}
.input-box {
    width: 100%;
    padding: 8px 0 8px 55px;
    border: none;
    outline: none;
    background-color: #010320;
    border-radius: 3px;
    color: #f5f5f5;
    font-size: 16px;
}
.password-toggle-icon {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #d6d6d6;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translate(0, -45%);
}
.error-message {
    margin-top: 5px;
    display: block;
    font-size: 14px;
    color: red;
}