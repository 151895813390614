.banner-img {
    width: 100%;
}
.swiper-pagination {
    text-align: center;
}
.swiper-pagination-bullet {
    background-color: #c865fa;
    transition: 0.5s;
}
.swiper-pagination-bullet-active {
    width: 40px;
    height: 10px;
    border-radius: 5px;
}