.amount-group {
    padding: 5px;
}
.amount span {
    background-color: transparent;
    border: 2px solid #f5f5f5;
    background-color: #f5f5f5;
    color: #000;
    width: 90px;
    height: 40px;
    display: block;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    border-radius: 5px;
    cursor: pointer;
}
.radio-custom {
    display: none;
}
.radio-custom:checked ~ .amount span{
    background-color: var(--info-color);
    color: #f5f5f5;
    border-color: var(--info-hover-color);
}