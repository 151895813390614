.card {
    padding: 20px;
    background-color: #1e2b3e;
    border-radius: 10px;
    /* max-width: calc(420px * 0.8); */
    max-width: 520px;
    border: 1px solid #1e2b3e;
    margin: 0 auto;
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
   .card {
        max-width: 420px;
   }
}