.auth-card-header {
    display: flex;
    align-items: center;
}
.auth-card-header span {
    text-transform: uppercase;
    display: block;
    letter-spacing: 1px;
    cursor: pointer;
    user-select: none;
    border-bottom: 2px solid transparent;
    padding: 10px;
    font-size: 14px;
}
.auth-card-header span.active {
    border-bottom-color: #c865fa;
}