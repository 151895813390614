.pagi-container {
    display: flex;
    list-style-type: none;
    user-select: none;
    align-items: center;
}
.pagi-container li {
   border: 1px solid #999;
   cursor: pointer;
   transition: 0.1s;
   color: #424242;
}
.pagi-container li a {
    display: block;
}
.pagi-container li:first-child {
    margin-right: 5px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    border: none;
}
.pagi-container li:last-child {
    margin-left: 5px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
    font-size: 20px;
    border: none;
}
.pagi-container li:not(:first-child):not(:last-child) {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    margin: 0 1px;
}
/* .pagi-container li:last-child:hover,.pagi-container li:first-child:hover  {
    background-color: var(--info-color);
    color: #FFF;
} */
.active {
    background-color: var(--main-color);
}
.active a {
    color: #FFF !important;
}
.pagi-container li a {
    color: var(--main-color);
}