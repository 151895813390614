.auth-card-body {
    padding: 20px;
}
.table-card-container {
    overflow: auto;
    font-size: 13px;
    color: #f5f5f5;
    max-height: 500px;
}
.table-card-container ul:first-child {
    position: sticky;
    top: 0;
    z-index: 99;
}
.table-card-container ul:first-child li{
    background-color: var(--main-color);
}
.table-header {
    display: flex;
    margin-bottom: 10px;
}
.table-header li:first-child {
    flex: 0 0 50px;
    border-radius: 10px 0 0 10px;
}
.table-header li:last-child {
    flex: 0 0 200px;
    border-radius: 0 10px 10px 0;
}
.table-header li:nth-last-child(2) {
    flex: 0 0 200px;
}
.table-header li {
    background-color: #131D44;
    flex: 0 0 100px;
    padding: 15px;
    text-align: center;
}

/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){

}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .table-card-container {
        max-height: 450px;
    }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
    .table-card-container {
        max-height: 400px;
    }
}