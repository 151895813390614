
.auth-card-header {
    padding-bottom: 30px;
    border-radius: 20px 20px 0 0;
    text-align: center;
    font-size: 16px;
}

.opt-input {
    width: 55px !important;
    height: 37px !important;
    border: 2px solid transparent;
    outline: none;
    font-size: 20px;
    border-radius: 5px;
    background-color: #131D44;
    color: #f5f5f5;
    user-select: none;
}
.opt-input:focus {
    border-color: #FFF;
}
.verify-submit {
    text-align: center;
    margin-top: 40px;
}
.error-message {
    margin-top: 5px;
    display: block;
    font-size: 15px;
    color: red;
}
/* Screen Width 1281px to Higher Resolution Desktops
--------------------------------------------------------------------------- */
@media (min-width : 1281px){

}


/* Laptop and Desktops
---------------------------------------------------------------------------- */
@media (min-width: 1025px) and (max-width: 1280px){

}


/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){
   .container-main {
      padding: 70px 15px;
   }
}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .container-main {
        padding: 70px 15px;
     }

     .auth-card {
        width: 100%;
     }
     .opt-input {
        width: 55px !important;
        height: 37px !important;
        font-size: 20px;
     }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
   .container-main {
      padding: 70px 15px;
   }
   .auth-card {
      width: 100%;
   }
   .opt-input {
    width: 40px !important;
    height: 30px !important;
    font-size: 16px;
   }
}