.payment-image {
    width: 70px;
    margin: 0 5px;
    cursor: pointer;
}
.payment-image img {
    width: 100%;
    border-radius: 10px;
}
.payment-list {
    position: relative;
}

.payment-check {
    display: none;
}
.check-icon {
    color: #16a34a;
    font-size: 16px;
    position: absolute;
    right: 10px;
    bottom: 5px;
}