.login-container {
    margin-top: 30px;
}
.forget-pass {
    width: 220px;
    margin: 20px auto 0 auto;
}
.forget-text {
    text-align: center;
    color: #f5f5f5;
}