.hover-underline::after {
    content: '';
    position: absolute;
    width: 40%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 14px;
    background-color: #c865fa;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
    border-radius: 5px;
  }
  
  .sidebar-icon:hover .hover-underline::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .dropDown-backDrop {
     position: fixed;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
     background-color: var(--main-color);
     z-index: 99;
  }

  .hover-underline-mobile::after {
    content: '';
    position: absolute;
    width: 10%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #c865fa;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
    border-radius: 5px;
  }
  
  .sidebar-icon-mobile:hover .hover-underline-mobile::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .sidebar-icon-mobile a {
    color: var(--main-color);
  }

  .profile {
    display: flex;
    align-items: center;
  }
  .profile .profile-img {
      width: 45px;
      border-radius: 50%;
      margin-right: 10px;
  }
  .profile .user-name {
      font-size: 16px;
      font-weight: bolder;
      margin-bottom: 5px;
  }
  .profile .user-balance {
      font-weight: bolder;
      color: gold;
      font-size: 16px;
  }
/* Tablet and IPad (portrait)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px){

}


/* Tablet and IPad (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 1024px) and (orientation : landscape){

}


/* Low Resolution Tablet and Mobile (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 481px) and (max-width: 767px) {
    .profile .user-name {
      font-size: 16px;
    }
    .profile .user-balance {
        font-size: 14px;
    }
}


/* Smartphones Mobiles (landscape)
----------------------------------------------------------------------------- */
@media (min-width: 320px) and (max-width: 480px) {
  .profile .user-name {
    font-size: 14px;
  }
  .profile .user-balance {
      font-size: 14px;
  }
}
